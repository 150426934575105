import logo from './logo.png';
import './Home.css';

function App() {
  /* TODO: FINISH TEST LANDING PAGE */
  return (


    <div className="App">
      <div id="division">
        <h1>SOFTWARE LIST</h1>
        <div id="division">
          <div id="sf-list-division">
            <h1 id="sf-list-division-title">
              ANTIFURRY WEBSITE (this website)
            </h1>
            <a href="/" target="_blank" rel="noreferrer" id="sf-list-division-external-link-button">Link to website</a>
            <h2 id="sf-list-division-active">
              ACTIVE
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
