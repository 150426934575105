import logo from './logo.png';
import './Home.css';

function App() {
  /* TODO: FINISH TEST LANDING PAGE */
  return (


    <div className="App">
      <div id="division">
      <a href="/ChangeLog" target="_blank" rel="noreferrer" id="upper-external-link-button">Change-log</a>
      <a href="/SoftwareList" target="_blank" rel="noreferrer" id="upper-external-link-button">Service/Software list</a>
      <a href="https://www.youtube.com/@andreiplsno" target="_blank" rel="noreferrer" id="upper-external-link-button">Creator's youtube channel</a>
      </div>

        <div id="division">
          <div id="division">
            <h1>Some anti-furry utilities/software i made or i will make soon:</h1>
          </div>

          <div id="division">
            <h1>Here will be the main utility</h1>
            <h3>There are a few utilities ill realease</h3>
            <h3>Nothing here yet!</h3>
          </div>

          <div id="scale-issues">
            <h1>This section was intentionally made invisible on this device because of scaling issues</h1>
          </div>

          <div id="gallery-division">
            <div id="gallery-division-containter">
            <h1>Nothing here</h1>
            <h2>Nothing else here (yet)</h2>
            <img src={logo} className="App-logo-small" alt="logo"/>
          </div>

          <div id="gallery-division-containter">
              <h1>Nothing here</h1>
              <h2>Nothing else here (yet)</h2>
              <img src={logo} className="App-logo-small" alt="logo"/>
          </div>

          <div id="gallery-division-containter">
              <h1>Nothing here</h1>
              <h2>Nothing else here (yet)</h2>
              <img src={logo} className="App-logo-small" alt="logo"/>
          </div>

          <div id="gallery-division-containter">
              <h1>Nothing here</h1>
              <h2>Nothing else here (yet)</h2>
              <img src={logo} className="App-logo-small" alt="logo"/>
          </div>

          </div>
        </div>

        <div id="division">
          <div id="division">
            <div id="division">
              <h1>What are anti-furries?</h1>
            </div>

            <div id="paragraph-point-listing">
              <div id="paragraph-point-division">
                <h2>Anti-furries are individuals that have a general dislike for the furry community.</h2>
              </div>

              <div id="paragraph-point-division">
                <h2>Anti-furries often try to stop and intercept furry activities to stop other individuals from becomming furries.</h2>
              </div>

              <div id="paragraph-point-division">
                <h2>Anti-furries often get more hate than furries do.</h2>
              </div>

              <div id="paragraph-point-division">
                <h2>Anti-furries do not spread hatred , they try to stop individuals from being in a bad community.</h2>
              </div>

              <div id="paragraph-point-division">
                <h2>Most antifurries quit because of the hatred , and the segregation we experience in many platforms.</h2>
              </div>
            </div>
          </div>

          <div id="division">
            <div id="division">
              <h1>What is the goal of this website?</h1>
            </div>

            <div id="paragraph-point-listing">

              <div id="paragraph-point-division">
                <h2>To provide and mentain a good and welcoming enviroment for anti-furries</h2>
              </div>

              <div id="paragraph-point-division">
                <h2>To provide clear instructions on how to become an antifurry</h2>
              </div>

              <div id="paragraph-point-division">
                <h2>To build a better reputation for anti-furries</h2>
              </div>

              <div id="paragraph-point-division">
                <h2>To make a place for anti-furries to chat (once the chat-app is done)</h2>
              </div>

              <div id="paragraph-point-division">
                <h2>To try to stop or minimize the hate anti-furries recive</h2>
              </div>

              <div id="paragraph-point-division">
                <h2>To unify antifurry so we can be stronger</h2>
              </div>

            </div>

          </div>

          <div id="division">
            <div id="division">
              <h1>Why do anti-furries hate furries?</h1>
            </div>

            <div id="paragraph-point-division">
                <h2>Haloguy's antifurry document explains the reasons very well</h2>
                <a href="https://docs.google.com/document/d/1LblJZAEzx2sRgjh0Y2J3R5RZ2OzV5kko5YVV4U6WkDM/edit">Haloguy's antifurry documents</a>
            </div>

            <div id="paragraph-point-division">
                <h2>On top of that , here are some added personal reasons:</h2>
            </div>

            <div id="paragraph-point-listing">

              <div id="paragraph-point-division">
                <h2>Furries spread everywhere like a virus</h2>
                <h3>Furries spread their content on all platforms with intents of trying to turn other individuals into furries. They try to get media/game/website creators to add furry content and LGBTQ content into their creations.</h3>
                <h3>They often get moderator/admin permissions by the owners , and they kick innocent antifurries of their game/website because they have a diffrent opinion than furries.</h3>
                <h3>Also they are often getting love and support from other non-furry inndividuals , as the furries indocternated modern pepole into being byased with furries.</h3>
              </div>

              <div id="paragraph-point-division">
                <h2>They are more toxic than a nuclear powerplant</h2>
                <h3>They are often very agressive and hostile when you have a diffrent opinion than them. They call you a "nazi" or an "edgy 13 year old" with no valid proof.</h3>
              </div>

              <div id="paragraph-point-division">
                <h2>Will add more reasons in the feature , check out haloguy's antifurry docs for more reasons</h2>
              </div>
            </div>

          </div>

        </div>

        <div id="division">
          <div id="division">
            <h1>Who we are?</h1>
            <h2>Who runs this website?</h2>
          </div>
          <div id="division">
            <h2>We are a group of antifurries set to make antifurry great again</h2>
            <h2>The owner and developer of this website is andreiplsno</h2>
            <a href="https://www.youtube.com/@andreiplsno" target="_blank" rel="noreferrer" id="external-link-button">andreiplsno's youtube channel</a>
          </div>
        </div>
    </div>
  );
}

export default App;
