import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home.js";
import Change from "./pages/ChangeLog.js";
import SoftwareList from "./pages/ServicesSoftware.js";
import NoPage from "./pages/NoPage.js";
import Landing from "./pages/Landing.js"


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="Home" element={<Home />}/>
        <Route path="SoftwareList" element={<SoftwareList />}/>
        <Route path="ChangeLog" element={<Change />}/>
        <Route path="/" element={<Landing />}/>
        <Route path="*" element={<NoPage />}/>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);