import logo from './logo.png';
import './Home.css';

function NoPage() {
  return (

    <div className="App">
      <div id = "coverup">
        <div id="start-division">
          <div id="division">
            <img src={logo} className="App-logo" alt="logo" id="spinicon"/>
          </div>
          <div id="division">
            <h1 id="title">404 NO PAGE FOUND</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoPage;
