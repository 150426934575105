import logo from './logo.png';
import './Home.css';

function App() {
  /* TODO: FINISH TEST LANDING PAGE */
  return (


    <div className="App">
      <div id="division">
        <h1>AF-COMMS CHANGELOG</h1>
        <div id="chg-division">
          <p>af-comms website 0.0.8 (Not public yet):</p>
          <p id="chg-greentxt" >+ Added upper buttons (/Home) (software list , change log , creator's youtube channel)</p>
          <p id="chg-greentxt" >+ Added changelog page (/ChangeLog) </p>
          <p id="chg-greentxt" >+ Added software/service page (/SoftwareList) </p>
        </div>
        <div id="chg-division">
          <p>af-comms website 0.0.9 (Not public yet):</p>
          <p id="chg-greentxt" >+ Tweaks to software/service page for it to look better on mobile devices (/SoftwareList) </p>
        </div>
        <div id="chg-division">
          <p>af-comms website 0.1.0:</p>
          <p id="chg-greentxt" >+ Added tags for website to be discoverable </p>
        </div>
      </div>
    </div>
  );
}

export default App;
